.login-box {
  padding: 25px;
  background-color: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small-container {
  margin-top: 25px;
  max-width: 500px;
  margin: 0 auto;
}

.post {
  margin: 5px;
  background-color: #ccc;
  padding: 10px;
  white-space: pre-line;
}

.task {
  margin: 5px;
  background-color: #ccc;
  padding: 10px;
  white-space: pre-line;
}
